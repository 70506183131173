@import "../fonts/font.css";
@import "swiper/swiper-bundle.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  @apply p-0 m-0 box-border scroll-smooth font-poppins;
}

body {
  @apply overflow-x-hidden;
}

.product-slider .swiper-button-prev,
.swiper-button-next {
  background-color: white;
  box-shadow: 3px 3px 8px 2px rgba(0, 0, 0, 0.3);
  padding: 2px;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  color: black;
}

.product-slider .swiper-button-prev::after,
.swiper-button-next::after {
  font-size: 16px;
}

.product-slider .swiper-slide {
  height: auto;
}
*::-webkit-scrollbar {
  width: 6px;
}

/* Track */
*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
}

/* Handle */
*::-webkit-scrollbar-thumb {
  background-color: #1b7ca0;
  border-radius: 10px;
}

@media (max-width: 767px) {
  .product-slider .swiper-button-prev,
  .swiper-button-next {
    padding: 2px;
    width: 28px;
    height: 28px;
  }

  .product-slider .swiper-button-prev::after,
  .swiper-button-next::after {
    font-size: 12px;
  }
}
